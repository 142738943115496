import get from 'lodash/get'

import config from 'app-config'
import { isWhiteLabel } from 'utils/whiteLabel'

export const getAppType = () => {
  if (get(config, 'MULTI_VENDOR_ENABLED') === '1') {
    return 'web_mvo'
  } else if (isWhiteLabel()) {
    return 'whitelabel'
  }

  return 'web'
}
