export const LOCAL_STORAGE_DINEIN_TABLE = 'heyyou-dinein-tableid'

export const LOCAL_STORAGE_DINEIN_TABLE_NOTIFICATION =
  'heyyou-dinein-tableid-notif'

export const COOKIE_SHOW_MODAL_ONCE = 'dinein-modal-shown'

export const STATUS_OFFLINE = 'OFFLINE'

export const STATUS_CLOSED = 'CLOSED'

export const STATUS_BUSY = 'BUSY_MODE'

export const STATUS_MENU_ITEM_UNAVAILABLE = 'UNAVAILABLE'

export const MAX_TAGS_COUNT = 3

export const TAG_MULTI_VENDOR_TYPE = 'MULTI-VENDOR'

export const LOCAL_STORAGE_RWG_TOKEN='_rwg_token'

export const LOCAL_STORAGE_GFO_MERCHANT = '_merchant_id'
